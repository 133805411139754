import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles'
import { Provider } from 'react-redux';
import { store } from './store';
import './App.css';
import Routes from './routes';
import Button from '@material-ui/core/Button';


const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#E6E6E6"
    },
    primary: {
      main: "#FFFFFF",
    }
  },
  typography: {
    subtitle1: { /* Table headers */
      fontWeight: '700',
      color: '#424242',
    },
    h5: {
      color: '#424242',
    },
    fontFamily: [
      '"Open Sans"',
      'sans-serif'
    ].join(',')
  },
    overrides: {
  MuiTableCell: {
    root: {
      padding: '14px 6px 14px 0px',
    },
  },
  MuiButton: {
    root: {
      backgroundColor: '#EF5350',
      textTransform: 'none',
      boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#EF5350',
        boxShadow: '0 3px 3px 0 rgba(0,0,0,0.14), 0 1px 7px 0 rgba(0,0,0,0.12), 0 3px 1px -1px rgba(0,0,0,0.2)',
      },
    },
  },
  },
});

const App = () => (
  <Provider store={store}>
    <div className="main">
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </div>
  </Provider>
);

export default App;
