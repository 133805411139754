const temp = []
if (process.env.NODE_ENV !== 'production') {
  temp.push(
    {
      label: "Hjem",
      pathname: "/"
    },
    {
      label: "Fullskjerm",
      pathname: "/fullscreen"
    },
    {
      label: "Smartinspection",
      pathname: "https://www.smartinspection.no",
      external: true
    },
    {
      label: "Demo page",
      pathname: "/demopage"
    }
  
  )
}
else{
  temp.push(
    {
      label: "Hjem",
      pathname: "/"
    },
    {
      label: "Fullskjerm",
      pathname: "/fullscreen"
    },
    {
      label: "Smartinspection",
      pathname: "https://www.smartinspection.no",
      external: true
    }
  )
}

const Menu = temp
export default Menu;