import {
    FETCH_TRAFFIC_COUNTERS_BEGIN,
    FETCH_TRAFFIC_COUNTERS_SUCCESS,
    FETCH_TRAFFIC_COUNTERS_FAILURE
} from './countTrafficActions';

const initialState = {
};
export default function trafficReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_TRAFFIC_COUNTERS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_TRAFFIC_COUNTERS_SUCCESS:
            var sensorId = action.payload.counters["sensor-id"];
            var data = {};
            data[sensorId] = action.payload.counters;

            return {
                ...state,
                loading: false,
                items: data
            };

        case FETCH_TRAFFIC_COUNTERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: []
            };

        default:
            return state;
    }
}