import {
  configureStore
} from "redux-starter-kit";
import { combineReducers } from "redux";
import { vehicles } from "./traffic";
import trafficReducer from "./traffic/trafficReducer"
import countTrafficReducer from "./traffic/countTrafficReducer"
import projectDataReducer from "./projectData/projectDataReducer"
import sessionReducer from "./redux/reducers/sessionsReducer"

const preloadedState = {
  vehicles: [
    {
      time: "2019-09-03T09:45",
      speed: 77,
      class: "Private"
    },
    {
      time: "2019-08-19T09:43",
      speed: 91,
      class: "Private"
    },
    {
      time: "2019-08-27T09:47",
      speed: 80,
      class: "Business"
    },
    {
      time: "2019-08-27T09:48",
      speed: 110,
      class: "Private"
    },
    {
      time: "2019-09-03T09:59",
      speed: 120,
      class: "Private"
    }
  ]
};

const reducer = combineReducers({
  vehicles: vehicles.reducer,
  traffic: trafficReducer,
  countTraffic: countTrafficReducer,
  projectData: projectDataReducer,
  session: sessionReducer
});

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
  preloadedState
});