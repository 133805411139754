export const fetchTraffic = (sensor, date) => {
    return (dispatch, getState) => {
        var traffic = getState().traffic;
        if(traffic.loading) {
            return;
        }
        dispatch(fetchTrafficBegin());
        return fetch(process.env.REACT_APP_TRAFFIC_API + "/sensors/"+ sensor +"/data/" + date)
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchTrafficSuccess({sensor, json}));
                return json;
            })
            .catch(error => dispatch(fetchTrafficFailure(error)));
    };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const FETCH_TRAFFIC_BEGIN = 'FETCH_TRAFFIC_BEGIN';
export const FETCH_TRAFFIC_SUCCESS = 'FETCH_TRAFFIC_SUCCESS';
export const FETCH_TRAFFIC_FAILURE = 'FETCH_TRAFFIC_FAILURE';

export const fetchTrafficBegin = () => ({
    type: FETCH_TRAFFIC_BEGIN
});

export const fetchTrafficSuccess = data => ({
    type: FETCH_TRAFFIC_SUCCESS,
    
    payload: {
        data,
        sensorid : data.sensor,
        traffic : data.json
    }
});

export const fetchTrafficFailure = error => ({
    type: FETCH_TRAFFIC_FAILURE,
    payload: {
        error
    }
});