export default class GraphColors{
    constructor(){
        this.colors = {
            mineralBlue : "#589299",
            mineralBlueLight : "#83AFB4",
            mineralBlueUltraLight : "#cddfe1", /* Added additional color for grahp */
            fluorescentRed : "#F6433B",
            coolGrey8C : "#808080",
            coolGrey1C : "#E6E6E6",
            }
    }
    colorize(){
        const colorArray = [this.colors.mineralBlue, this.colors.mineralBlueLight, this.colors.mineralBlueUltraLight]
        return colorArray;
    }
}
