import React from "react";
import { connect } from "react-redux";
import PassingsVehicleSizeGraph from "./PassingsVehicleSizeGraph"
import PassingsVehicleSizeHeader from "./PassingsVehicleSizeHeader"
import { fetchTraffic } from "../../traffic/trafficActions";
import moment from 'moment'


class PassingsVehicleSize extends React.Component {
 componentDidMount() {
    const date = moment().subtract(1,'days').format('YYYY-MM-DD')
    this.props.dispatch(fetchTraffic(this.props.sensorid, date));
    let timer = setInterval(() => this.props.dispatch(fetchTraffic(this.props.sensorid, date)), 60*60*1000);
    this.setState({timer});
  }
  componentWillUnmount() {
    clearInterval(this.state.timer);
  } 


  render() {
    return (
        <div>
          <PassingsVehicleSizeHeader direction={ this.props.direction  } sensorid={ this.props.sensorid } />
          <PassingsVehicleSizeGraph lanes={ this.props.lanes } sensorid={ this.props.sensorid }/>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  traffic: state.traffic.items,

});

export default connect(mapStateToProps)(PassingsVehicleSize);

