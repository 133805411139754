import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Link as MaterialLink } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import Menu from './Menu';
import cognitoUtils from '../../lib/cognitoUtils'
import { ReactComponent as Logo } from './nye_veier_liggende_hvit.svg';

const styles = theme => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey['100']}`,
    backgroundColor: '#F6433B',

  },
  inline: {
    display: 'inline'
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  tagline: {
    display: 'inline-block',
    marginLeft: 10,
    [theme.breakpoints.up('md')]: {
      paddingTop: '0.8em'
    }
  },
  iconContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  iconButton: {
    float: 'right'
  },
  tabContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  tabItem: {
    paddingTop: 5,
    paddingBottom: 5,
    minWidth: 'auto',
    color: '#FFF',
    transition: 'easeInOut .5|s',
    '&:hover': {
      textDecoration: 'none',
      color: '#e6e6e6',
      transition: 'easeInOut .5s'
    }
  },
  smartlake: {
    color: '#FFF',
  }
})

const mapStateToProps = state => {
  return { session: state.session }
}

class Topbar extends Component {

  state = {
    value: 0,
    menuDrawer: false
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  mobileMenuOpen = (event) => {
    this.setState({ menuDrawer: true });
  }

  mobileMenuClose = (event) => {
    this.setState({ menuDrawer: false });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  current = () => {
    if (this.props.currentPath === '/home') {
      return 0
    }
    if (this.props.currentPath === '/fullscreen') {
      return 1
    }
    if (this.props.currentPath === '/demopage') {
      return 2
    }
  }

  onSignOut = (e) => {
    e.preventDefault()
    cognitoUtils.signOutCognitoSession()
  }

  render() {

    const { classes } = this.props;

    return (
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs={4}  className={classes.hack}>
              <div className="logo-container">
                <Logo />
              </div>
            </Grid>

            <Grid item xs={4} className={classes.hackCenter}>
              <div style={{"text-align": "center"}}>
                <Typography variant="h4" className={classes.smartlake}>Smartlake</Typography>
              </div>
            </Grid>

            <Grid item xs={4} className={classes.hack}>
              <Grid container spacing={1} alignItems="baseline">
                <Grid item xs={12} className={classes.flex}>
                  {!this.props.noTabs && (
                    <React.Fragment>
                      <div className={classes.iconContainer}>
                        <IconButton onClick={this.mobileMenuOpen} className={classes.iconButton} color="primary" aria-label="Menu">
                          <MenuIcon />
                        </IconButton>
                      </div>
                      <div className={classes.tabContainer}>
                        <SwipeableDrawer anchor="right" open={this.state.menuDrawer} onClose={this.mobileMenuClose} onOpen={this.mobileMenuOpen}>
                          <AppBar title="Menu" />
                          <List>
                            {Menu.map((item, index) => (
                              <ListItem component={item.external ? MaterialLink : Link} href={item.external ? item.pathname : null} to={item.external ? null : { pathname: item.pathname, search: this.props.location.search }} button key={item.label}>
                                <ListItemText primary={item.label} />
                              </ListItem>
                            ))}
                          </List>
                        </SwipeableDrawer>
                        <Tabs
                          value={this.current() || this.state.value}
                          indicatorColor="primary"
                          textColor="secondary"
                          onChange={this.handleChange}
                        >
                          {Menu.map((item, index) => (
                            <Tab key={index}
                              component={item.external ? MaterialLink : Link}
                              href={item.external ? item.pathname : null}
                              to={item.external ? null : { pathname: item.pathname, search: this.props.location.search }}
                              classes={{ root: classes.tabItem }}
                              label={item.label} />
                          ))}
                        </Tabs>
                      </div>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    )
  }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Topbar)))
