import React,  { Component } from 'react';
import { connect } from "react-redux";
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import PassingsPerHour from "../../../components/PassingsPerHour/PassingsPerHour"
import PassingsVehicleSize from '../../../components/PassingsVehicleSize/PassingsVehicleSize';
import { WidthProvider, Responsive } from "react-grid-layout";

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#E6E6E6',
    overflow: 'hidden',
    paddingBottom: 200
  },
  grid: {
    margin: `0 ${theme.spacing(2)}px`,
  //  margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    },
  },
  loadingState: {
    opacity: 0.05
  },
  paper: {
    padding: theme.spacing(3),
  //  margin: theme.spacing(2),
    textAlign: 'left',
    minHeight: '100%',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
     padding: theme.spacing(1),
    }
  },
  rangeLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(3)
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  outlinedButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing(1)
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing(1),
    width: 152,
    height: 36
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  block: {
    padding: theme.spacing(2),
  },
  inlining: {
    display: 'inline-block',
    marginRight: 10
  },
  buttonBar: {
    display: 'flex'
  },
  noBorder: {
    borderBottomStyle: 'hidden'
  },
  mainBadge: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  }
});


const ResponsiveGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layouts: JSON.parse(JSON.stringify(originalLayouts))
    };
  }

  static get defaultProps() {
    return {
      className: "layout",
      cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    };
  }

  resetLayout() {
    this.setState({ layouts: {} });
  }

  onLayoutChange(layout, layouts) {
    saveToLS("layouts", layouts);
    this.setState({ layouts });
  }
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
        <ResponsiveGridLayout
          className="layout"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          breakpoints= {{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
          rowHeight={124}
          margin= {{xxs:[10, 70], sm:[30, 70], md:[30,70], lg:[30,40]}}
          layouts={this.state.layouts}
          onLayoutChange={(layout, layouts) =>
            this.onLayoutChange(layout, layouts)
          }
        >
        <div key="1" data-grid={{w: 6, h: 3, x: 0, y: 0, minW: 6, minH: 3}}>
          <Paper className={classes.paper}>
          <div>
            <PassingsPerHour lanes={ [ 'Felt 3', 'Felt 1' ]} direction="Kristiansand" sensorid="61942V2809673" />
          </div>
          </Paper>
        </div>
        <div key="2" data-grid={{w: 6, h: 3, x: 6, y: 0, minW: 6, minH: 3}}>
          <Paper className={classes.paper}>
            <div>
              <PassingsPerHour lanes={ [ 'Felt 4', 'Felt 2' ]} direction="Oslo" sensorid="61942V2809673"/>
            </div>
          </Paper>
        </div>
        <div key="3" data-grid={{w: 6, h: 3, x: 0, y: 3, minW: 6, minH: 3}}>
          <Paper className={classes.paper}>
            <div>
              <PassingsVehicleSize lanes={ ['Tungtransport Vest', 'Person Vest'] } direction="Kristiansand" sensorid="61942V2809673" />
            </div>
          </Paper>
        </div>
        <div key="4" data-grid={{w: 6, h: 3, x: 6, y: 3, minW: 6, minH: 3}}>
        <Paper className={classes.paper}>
                  <div>
                    <PassingsVehicleSize lanes={ ['Tungtransport Øst', 'Person Øst'] } direction="Oslo" sensorid="61942V2809673"/>
                  </div>
                </Paper>
        </div>
      </ResponsiveGridLayout>
        <button className="resetButton MuiButtonBase-root MuiButton-root MuiButton-text" onClick={() => this.resetLayout()}>Nullstill posisjon</button>
        </div>
      </React.Fragment>
    )
  }
}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value
      })
    );
  }
}



const mapStateToProps = state => ({
});

export default withRouter(withStyles(styles)(connect(mapStateToProps)(Dashboard)));
