import moment from 'moment'

export function fetchTrafficCounters() {
    return dispatch => {
        dispatch(fetchTrafficBegin());
        var date = moment().subtract(1,'days').format('YYYY-MM-DD');
        return fetch(process.env.REACT_APP_TRAFFIC_API + "/sensors/61942V2809673/counters/" + date)
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchTrafficCountersSuccess(json));
                return json;
            })
            .catch(error => dispatch(fetchTrafficCountersFailure(error)));
    };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const FETCH_TRAFFIC_COUNTERS_BEGIN = 'FETCH_TRAFFIC_COUNTERS_BEGIN';
export const FETCH_TRAFFIC_COUNTERS_SUCCESS = 'FETCH_TRAFFIC_COUNTERS_SUCCESS';
export const FETCH_TRAFFIC_COUNTERS_FAILURE = 'FETCH_TRAFFIC_COUNTERS_FAILURE';

export const fetchTrafficBegin = () => ({
    type: FETCH_TRAFFIC_COUNTERS_BEGIN
});

export const fetchTrafficCountersSuccess = counters => ({
    type: FETCH_TRAFFIC_COUNTERS_SUCCESS,
    payload: {
        counters
    }
});

export const fetchTrafficCountersFailure = error => ({
    type: FETCH_TRAFFIC_COUNTERS_FAILURE,
    payload: {
        error
    }
});