import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 500,
  },
});

const GraphMessanges = ({traffic, loading, error}) => {
  const classes = useStyles();


  if (error) {
    return (
      <div className={classes.root}>
          <Typography variant="h6" gutterBottom>
            Error! {error.message}    
          </Typography>
      </div>
    )
  }

  if (loading) {
    return (
      <div className={classes.root}>
          <Typography variant="h6" gutterBottom>
              Loading...     
          </Typography>
      </div>
    )
  }

  if(!traffic) {
    return (
      <div className={classes.root}>
          <Typography variant="h6" gutterBottom>
              No traffic   
          </Typography>
      </div>
    )
  }

  return (
    <div className={classes.root}>
        <Typography variant="h6" gutterBottom>
          Error!   
        </Typography>
    </div>
  )
}


const mapStateToProps = state => ({
  traffic: state.traffic.items,
  loading: state.traffic.loading,
  error: state.traffic.error
});

export default connect(mapStateToProps)(GraphMessanges);