import React,  { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import Topbar from '../../../components/MainComponents/Topbar';
import Buttons from '../../../components/DemoComponents/Buttons';
import SplitButton from '../../../components/DemoComponents/SplitButton';
import Checkboxes from '../../../components/DemoComponents/Checkboxes';
import DateTimePicker from '../../../components/DemoComponents/DateTimePicker';
import RadioButtons from '../../../components/DemoComponents/RadioButtons';
import NativeSelect from '../../../components/DemoComponents/NativeSelect';
import DiscreteSlider from '../../../components/DemoComponents/DiscreteSlider';
import Switches from '../../../components/DemoComponents/Switches';
import TextFields from '../../../components/DemoComponents/TextFields';
import IntegratedAutocomplete from '../../../components/DemoComponents/IntegratedAutocomplete';
import CustomizedBreadcrumbs from '../../../components/DemoComponents/CustomizedBreadcrumbs';
import ResponsiveDrawer from '../../../components/DemoComponents/ResponsiveDrawer';
import Links from '../../../components/DemoComponents/Links';
import CustomizedMenus from '../../../components/DemoComponents/CustomizedMenus';
import CustomizedSteppers from '../../../components/DemoComponents/CustomizedSteppers';
import CustomizedTabs from '../../../components/DemoComponents/CustomizedTabs';
import AppBar from '../../../components/DemoComponents/AppBar';
import Paper from '../../../components/DemoComponents/Paper';
import Cards from '../../../components/DemoComponents/Cards';
import CustomizedSnackbars from '../../../components/DemoComponents/CustomizedSnackbars';
import Progress from '../../../components/DemoComponents/Progress';
import LinearProgress from '../../../components/DemoComponents/LinearProgress';
import SimpleList from '../../../components/DemoComponents/SimpleList';
import SwitchList from '../../../components/DemoComponents/SwitchList';
import CustomizedTables from '../../../components/DemoComponents/CustomizedTables';
import CustomizedTooltips from '../../../components/DemoComponents/CustomizedTooltips';
import Typography from '../../../components/DemoComponents/Typography';

class DemoPage extends Component {

    render() {
        return (
            <React.Fragment>
            <CssBaseline />
            <Topbar />
            <Buttons />
            <SplitButton />
            <Checkboxes />
            <DateTimePicker />
            <RadioButtons />
            <NativeSelect />
            <DiscreteSlider />
            <Switches />
            <TextFields />
            <IntegratedAutocomplete />
            <CustomizedBreadcrumbs />
            {/* <ResponsiveDrawer /> */} {/* keep disabeled? */}
            <Links />
            <CustomizedMenus />
            <CustomizedSteppers />
            <CustomizedTabs />
            <AppBar />
            <Paper />
            <Cards />
            <CustomizedSnackbars />
            <Progress />
            <LinearProgress />
            <SimpleList />
            <SwitchList />
            <CustomizedTables />
            <CustomizedTooltips />
            <Typography />
            </React.Fragment>
        )
        }
    }

    export default withRouter(DemoPage);
    