import React from 'react'
import { Route, Router } from 'react-router-dom'
import Dashboard from './View/Main/Dashboard/Dashboard'
import Callback from './components/Callback'
import Main from './View/Main/Main'
import DemoPage from './View/Main/DemoPage/DemoPage'
import ScrollToTop from './components/ScrollTop'

import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

export default props => (
        <Router history={history}>
          <Route exact path='/' component={ Main } />
          <Route exact path='/callback' component={ Callback } />
          <Route exact path='/fullscreen' component={ Dashboard } />
          <Route exact path='/demopage' component={ DemoPage } />
        </Router>
  )