import React from 'react';
import { connect } from "react-redux";
import _ from 'underscore'
import moment from 'moment'
import GraphMessages from '../common/GraphMessages'
import GraphColors from '../common/GraphColors'
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import "../common/Tooltip.css"; // INGE - USIKKER PÅ HVOR DET ER GREIEST MED IMPORT?

class PassingsPerHourGraph extends React.Component {
  render() {
    const { error, loading, traffic, sensorid } = this.props;

    if(error || loading || !traffic || !(sensorid in traffic)){
      return <div>
        <GraphMessages />
      </div>
    }

    const groupedByTime = _.groupBy(traffic[sensorid].data, function(n)
      {
        return moment(n.Fra).format('HH:mm')
      })
    const data = _.map(groupedByTime, function(group, time) {
      return {
        x: time,
        "Felt 1": group[0].Volum,
        "Felt 2": group[1].Volum,
        "Felt 3": group[2].Volum,
        "Felt 4": group[3].Volum
      }
    })

    var myGraphColors = new GraphColors()
    return (
      <div style={{height: 300}}>
        <ResponsiveContainer width="100%" height="100%">
              <AreaChart
        //width={500}
        height={400}
        data={data}
        margin={{
          top: 10, right: 10, left: -25, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="9 9" />
        <XAxis dataKey="x" />
        <YAxis />
        <Tooltip wrapperStyle={{
          backgroundColor: myGraphColors.colorize()[1],
          borderRadius: '4px',
          }}/>
        <Area type="monotone" dataKey={this.props.lanes[0]} stackId="1" stroke="#000000" fill={myGraphColors.colorize()[1]} />
        <Area type="monotone" dataKey={this.props.lanes[1]} stackId="1" stroke="#000000" fill={myGraphColors.colorize()[2]} />
      </AreaChart>
      </ResponsiveContainer>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  traffic: state.traffic.items,
  loading: state.traffic.loading,
  error: state.traffic.error
});

export default connect(mapStateToProps)(PassingsPerHourGraph);
