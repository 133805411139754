import {
    FETCH_TRAFFIC_BEGIN,
    FETCH_TRAFFIC_SUCCESS,
    FETCH_TRAFFIC_FAILURE
} from './trafficActions';

const initialState = {
    items: [],
    loading: false,
    error: null
};

export default function trafficReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_TRAFFIC_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_TRAFFIC_SUCCESS:
            /* action.payload.sensorid : action.payload.traffic */
            var items = {}
            items[action.payload.sensorid] = action.payload.traffic
            return {
                ...state,
                items,
                loading: false
                
            };

        case FETCH_TRAFFIC_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: []
            };

        default:
            return state;
    }
}