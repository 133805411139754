const initialState = {
    items: {
        NV12000 : {
            projectName : ["KBT", "E18-TA", "E6-AM", "E39-MM"],
            usedStorage : 2.55,
            total:{
                PDF : 2000,
                "Point Cloud" : 1300,
                Other : 2300,
            },  
        }, 
    },
    loading: false,
    error: null
};

export default function projectDataReducer(state = initialState){
    return state
}