import { createSlice, createSelector } from "redux-starter-kit";

const addVehicle = (state, action) => [
  ...state,
  {
    id: state.reduce((maxId, todo) => Math.max(todo.id, maxId), -1) + 1,
    completed: false,
    text: action.payload.text
  }
];

const vehicles = createSlice({
  slice: "vehicles",
  initialState: [],
  reducers: {
    add: addVehicle
  }
});

vehicles.selectors.getVehiclesCount = createSelector(
  [vehicles.selectors.getVehicles],
  vehicles =>
    vehicles
);

export { vehicles };
