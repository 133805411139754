/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  link: {
    margin: theme.spacing(1),
  },
}));

export default function Links() {
  const classes = useStyles();
  
  return (
    <Typography>
      <Link href="#" className={classes.link}  onClick={() => {
    alert("I'm a button.");
  }}>
        Link
      </Link>
      <Link href="#" color="inherit" className={classes.link}  onClick={() => {
    alert("I'm a button.");
  }}>
        {'color="inherit"'}
      </Link>
      <Link href="#" variant="body2" className={classes.link}  onClick={() => {
    alert("I'm a button.");
  }}>
        {'variant="body2"'}
      </Link>
    </Typography>
  );
}