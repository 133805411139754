import React from "react";
import { connect } from "react-redux";
import _ from 'underscore'
import moment from 'moment'
import GraphMessages from '../common/GraphMessages'
import GraphColors from "../common/GraphColors";
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';

class PassingsVehicleSizeGraph extends React.Component {
  render() {
    const { error, loading, traffic, sensorid } = this.props;

    if(error || loading || !traffic || !(sensorid in traffic)){
      return <div>
        <GraphMessages />
      </div>
    }
    const groupedByTime = _.groupBy(traffic[sensorid].data, function(n)
      {
        return moment(n.Fra).format('HH:mm')
      })

    const data = _.map(groupedByTime, function(group, time) {
      return {
        x: time,
        "Tungtransport Vest": Number(group[0].LargeSize) + Number(group[2].LargeSize),
        "Person Vest": Number(group[0].SmallSize) + Number(group[2].SmallSize),
        "Tungtransport Øst": Number(group[1].LargeSize) + Number(group[3].LargeSize),
        "Person Øst": Number(group[1].SmallSize) + Number(group[3].SmallSize)
      }
    })

  const getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0;
  return toPercent(ratio, 2);
  };

  const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;
  const renderTooltipContent = (o) => {
  const { payload, label } = o;
  const total = payload.reduce((result, entry) => (result + entry.value), 0);
    return (
      <div className="customized-tooltip-content">
        <p className="total">{`${label} (Total: ${total})`}</p>
        <ul className="list">
          {
            payload.map((entry, index) => (
              <li key={`item-${index}`} style={{ color: entry.color }}>
                {`${entry.name}: ${entry.value} (${getPercent(entry.value, total)})`}
            </li>
            ))
          }
        </ul>
      </div>
  );
};

    const myGraphColors = new GraphColors()
    return (
      <div style={{height: 300}}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
          //  width={100}
            height={400}
            data={data}
            stackOffset="expand"
            margin={
              {
                top: 10, right: 10, left: -15, bottom: 0,
              }
            }
          >
          <CartesianGrid strokeDasharray="9 9"/> /* ADDED STROKE TO ADD OPACITY TO strokeDasharray */
          <XAxis dataKey={"x"} />
          <YAxis tickFormatter={toPercent} />
          <Tooltip content={renderTooltipContent} wrapperStyle={{
            backgroundColor: myGraphColors.colorize()[1],
            borderRadius: '4px',
          }}/>
          <Area type="monotone" dataKey={this.props.lanes[0]} stackId="1" stroke="#000000" fill={myGraphColors.colorize()[1]} />
          <Area type="monotone" dataKey={this.props.lanes[1]} stackId="1" stroke="#000000" fill={myGraphColors.colorize()[2]} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  traffic: state.traffic.items,
  loading: state.traffic.loading,
  error: state.traffic.error
});

export default connect(mapStateToProps)(PassingsVehicleSizeGraph);
