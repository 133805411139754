import React from 'react';
import { connect } from "react-redux";
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const styles = theme => ({
  root: {
    width: '100%',
  },
});

class PassingsVehicleSizeGraphHeader extends React.Component {
  render() {
    const { classes, traffic, sensorid } = this.props;

    var name = "Henter data"
    var veireferanse = ""
    var date = ""

    if (traffic && sensorid in traffic && traffic[sensorid].data && traffic[sensorid].data.length > 0) {
      name = traffic[sensorid].data[0].Navn
      veireferanse = traffic[sensorid].data[0].Vegreferanse
      date = traffic[sensorid].date
    }

    return (
      <Grid justify="space-between" container className={classes.root}>
        <Grid item xs={12} sm={10}>
        <Typography variant="h5">
            Prosentandel tungtransport
        </Typography>
        </Grid>
        <Grid item xs={12} sm={10} gutterBottom>
          <Typography variant="h6">
              { name } tellepunkt, retning { this.props.direction }
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" style={{display:"none"}}> /* MOVE TO MORE INFO SCREEN */
              { veireferanse }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="subtitle2" style={{marginBottom:"1rem"}}>
              { date }
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  traffic: state.traffic.items,
});

export default connect(mapStateToProps)(withStyles(styles)(PassingsVehicleSizeGraphHeader));
